.hays-header{
  height: initial;
  background: white;
  padding: 0;
  .separator{
    border-top: 1px solid #e5e5e5;
    margin: 10px 15px;
  }
}

.language-row{
  background: white;
  border-bottom: 1px solid #f5f5f5;
  //margin-bottom: 10px;

  .ant-select{
    width: initial !important;
  }

  .ant-select-focused .ant-select-selector,
  .ant-select-selector:focus,
  .ant-select-selector:active,
  .ant-select-open .ant-select-selector {
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
  }
}

.header-main-wrapper{
  background-color: white;
  text-align: left;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  img{
    cursor: pointer;
    max-height: 100%;
    float: left;
    text-align: left;
    max-width: 140px;
    max-width: fit-content;
  }

}

.ant-layout-footer{
  text-align: left;
}

#header-main {
  justify-content: space-between;
}

.logo-wrapper{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.right-nav{
  display: flex;
  .search-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    #site-search{
      height: 33px;

      .hays_form{
        height: 100%;
        position: relative;
        line-height: 0;

        input{
          margin: 0;
          display: inline-block;
          border: 0;
          background: #eee;
          width: 100%;
          font-size: 14px;
          padding: 0 60px 0 10px;
          min-height: 33px;
          height: 33px;
        }
        button{
          height: 70%;
          margin: 2%;
          position: absolute;
          top: 0;
          right: 5px;
          font-size: 16px;
          border: none;
          display: flex;
          flex-direction: column;
          justify-content: center;
          background: none;
          img{
            width: 14px;
          }
        }
      }
    }
  }

  #nav-social{
    margin-left: 10px;
    position: relative;
    top: 2px;

    ul {
      display: flex;
      list-style: none;
      padding: 0;
      margin: 0;

      li{
        height: 33px;
        background: #1c8dd2;
        margin-left: 5px;
        padding: 5px;
        line-height: 0;

        a{
          margin-left: 0;
          margin-right: 0;
          padding: 0;
          font-size: 16px;
          color: #fff;
          position: relative;
          top: 3px;
        }
      }
    }
  }
}

.icon-linkedin:before {
  line-height: 0;
  font-family: "hays-branding-icons";
  content: '\f0e1';
}

.icon-twitter:before {
  font-family: "hays-branding-icons";
  content: '\f099';
}

.icon-facebook:before {
  font-family: "hays-branding-icons";
  content: '\f09a';
}
.icon-wechat:before {
  font-family: "hays-branding-icons";
  content: '\f1d7';
}
.icon-youku:before {
  font-family: "hays-branding-icons";
  content: '\f324';
}
.icon-insta:before {
  font-family: "hays-branding-icons";
  content: '\f16d';
}

[class^="icon-"]:before, [class*=" icon-"]:before {
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: 0.2em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"], [class*=" icon-"] {
  display: inline;
  width: auto;
  height: auto;
  line-height: normal;
  vertical-align: baseline;
  background-image: none;
  background-position: 0% 0%;
  background-repeat: repeat;
  margin-top: 0;
}

#nav-main{
  position: relative;

  &>ul{
    list-style: none;
    display: flex;
    justify-content: flex-start;
    padding: 0;
    margin-bottom: 1rem;
    height: 20px;
    line-height: initial;
    margin-left: 14px;
    margin-top: 5px;

    li{
      margin-right: 35px;
      color: #009ed9;
      cursor: pointer;
      font-weight: 500;
      .ant-dropdown-trigger {
        background: none;
        border: none;
        cursor: pointer;
        padding: 0;
      }
      a{
        color: #009ed9;
        text-decoration: none;
        font-weight: 500;
        font-size: 14px;
      }
      .arrow-down-icon {
        &:before {
          font-family: "hays-branding-icons";
          content: '\e809';
          color: #009ed9;
          display: inline-block;
          width: 13px;
          height: 7px;
        }
      }
    }
  }

  #hays-worldwide{
    background: #009ed9;
    border-top: 3px solid #002776;
    position: absolute;
    //right: -20px;
    //top: 50px;
    //z-index: 1000;
    width: 100%;
    padding: 15px 0 22px 120px;
    display: none;

    &.open{
      display: block;
    }

    .hays-col{
      display: block;
      float: left;
      margin: 0 10px;
      position: relative;
      li{
        line-height: initial;
        list-style: none;
        margin-right: 30px;
        text-align: left;
        margin-bottom: 5px;
        a{
          color: #fff;
          font-size: 14px;
          text-decoration: none;
        }
      }
    }
  }
}

.hays-col-2 {
  width: 180px;
}


.green-separator {
  position: relative;

  .gray-progress-background{
    width: 70%;
    background-color: #f6f1eb;
    position: relative;
    height: 100%;
    margin: 0 auto;
    .blue-progress{
      width: 10%;
      height: 100%;
      background-color: #213a6f;
      position: relative;
      transition: 0.5s ease-out;
      .arrow-down {
        position: absolute;
        width: 0;
        height: 0;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-top: 15px solid #253e72;
        top: 10px;
        right: 40px;
      }
    }
  }
}

.debug{
  position: absolute;
  left: 10px;
  top: -16px;
}
.hays-header{
  position: relative;
}

.megaMenuOverlay {
  background: #ccecf8;
  width: 100%;
  max-width: 1200px;
  left: 0 !important;
  border-top: 2px solid #002776;
  padding: 20px;
  .ant-col {
    text-align: left;
  }
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    li {
      margin-bottom: 5px;
      a {
        color: #009ed9;
        &:hover {
          color: #002776;
        }
      }
    }
  }
}