.page-start-wrapper{
  width: 100%;
  height: 100%;
  padding-top: 17px;
  .start-desc {
    background: url("./assets/bg-desc-start.jpg") no-repeat top right;
    background-size: auto 100%;
    min-height: 154px;
    padding-top: 25px;
    margin: 0 0 30px;
    p {
      max-width: 650px;
      text-align: left;
      font-size: 18px;
    }
  }
  .select-questions {
    padding: 0 30px;
    .select-questions-items {
      .q-item {
        display: flex;
        flex-direction: column;
        color: #fff;
        text-align: center;
        font-size: 16px;
        padding-top: 5px;
        min-height: 170px;
        margin-bottom: 30px;
        justify-content: space-between;
        .ant-select {
          width: 100%;
          text-align: left;
          .ant-select-selector {
            .ant-select-selection-placeholder {
              color: #fff;
              font-size: 16px;
            }
            &:after {
              background: url("./assets/arrow-down.png") no-repeat;
              width: 31px;
              height: 20px;
              background-size: cover;
              visibility: visible;
              margin-top: 9px;
              transition: 0.3s all linear;
            }
          }
          .ant-select-arrow,
          .ant-select-selection-item {
            color: #fff;
          }
        }
        &.sports {
          background-color: #0b6d8e;
          .ant-select {
            .ant-select-selector {
              background-color: #007fa9 !important;
            }
          }
        }
        &.dream {
          background-color: #e58416;
          .ant-select {
            .ant-select-selector {
              background-color: #f7911e !important;
            }
          }
        }
        &.apparel {
          background-color: #7f438e;
          .ant-select {
            .ant-select-selector {
              background-color: #9356a2 !important;
            }
          }
        }
        &.food {
          background-color: #d84d90;
          .ant-select {
            .ant-select-selector {
              background-color: #ed5ca2 !important;
            }
          }
        }
        img {
          margin: auto;
          object-fit: cover;
        }
      }
    }
  }
}

.ant-select-dropdown {
  &.custom-dropdown {
    .ant-select-item {
      color: #fff;
      &.ant-select-item-option-active,
      &.ant-select-item-option-selected {
        color: #002776;
      }
    }
  }
}
.sports-dropdown {
  background-color: #007fa9;
}
.dream-dropdown {
  background-color: #f7911e;
}
.apparel-dropdown {
  background-color: #9356a2;
}
.food-dropdown {
  background-color: #ed5ca2;
}

.lets-go-btn {
  background-color: #233e82 !important;
  color: #fff !important;
  transition: 0.3s all linear;
  margin-bottom: 20px;
  line-height: 1.2;
  &:hover {
    background-color: #00a0ff !important;
    color: #fff !important;
    transition: 0.3s all linear;
  }
  &.disabled {
    //background-color: #a7b2cd !important;
  }
  .ant-spin {
    span {
      i {
        background-color: #fff !important;
      }
    }
  }
}
.lets-go-btn.hide{
  opacity: 0;
  pointer-events: none;
  transition: 0.3s all linear;
}
.lets-go-btn.show{
  opacity: 1;
}

.ant-select-open .ant-select-selector {
  &:after {
    transform: rotate(180deg);
  }
}

@media screen and (max-width: 768px) {

  .page-start-wrapper{
    .start-desc{
      padding-top: 0;
      background-image: initial;
      img{
        //width: 100%;
        //padding-left: 6%;
      }
    }

    .select-questions{
      padding: 0;
      .select-questions-items {
        margin-bottom: 0;
      }
    }
  }

  .lets-go-btn {
    background-color: #233e82 !important;
    color: #fff !important;
    transition: 0.3s all linear;
    &:hover {
      background-color: #233e82 !important;
      color: #fff !important;
      transition: 0.3s all linear;
    }
  }

}