.pie {
  width: 240px;
  aspect-ratio: 1;
  display: inline-grid;
  place-content: center;
  margin: 5px;
  font-size: 25px;
  font-weight: bold;
  font-family: sans-serif;
  position: absolute;
  transition: 0.3s all linear;
  left:0;
  top:0;
  &:before{
    content: "";
    position: absolute;
    border-radius: 50%;
    inset: 0;
  }
}

.no-round {
  &:before {
    background-size: 0 0, auto;
  }
  :after {
    content: none;
  }
}

.pie-left{
  transform: rotate(159deg);
  z-index: 1;
  animation: pie2 1s .5s both;
  &:before {
    background: conic-gradient(#f775af, 31%, #0000 0);
  }
}

.pie-right{
  transform: rotate(90deg);
  z-index: 1;
  animation: pie3 1s .5s both;
  &:before {
    background: conic-gradient(#0b2672, 19%, #0000 0);
  }
}

.pie-bottom{
  transform: rotate(-90deg);
  z-index: 2;
  animation: pie1 1s .5s both;
  &:before {
    background: conic-gradient(#fda433, 50%, #0000 0);
  }
}

@keyframes p{
  from { background: conic-gradient(#fda433, 0%, #0000 0); }
  to { background: conic-gradient(#fda433, 50%, #0000 0); }
}

@keyframes pie1{
  from {
    transform: rotate(-90deg)
  }
  to{
    transform: rotate(90deg)
  }
}

@keyframes pie2{
  from {
    transform: rotate(159deg)
  }
  to{
    transform: rotate(-90deg)
  }
}

@keyframes pie3{
  from {
    transform: rotate(90deg)
  }
  to{
    transform: rotate(22deg)
  }
}