.page-question-wrapper{
  position: relative;
  .page-question{
    padding-top: 30px;
  }

  .go-up, .go-back{
    //position: absolute;
    //bottom: -41px;
    //right: 0px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    background-color: #002776;
    //display: none;
    @media screen and (max-width: 768px) {
      //bottom: -34px;
      //display: block;
      //right: 0;
    }
  }
  .go-back{
    //display: block;
    @media screen and (max-width: 768px) {
      //left:0;
      //right: initial;
    }
  }
}


.question-inner{
  min-height: 400px;
  margin-bottom: 15px;
  .question-img-left {
    .ant-image {
      display: block;
      height: 100%;
      width: 100%;
    }
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  .question-wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
    .question-text-top {
      height: 52%;
      position: relative;
      padding: 20px 180px 30px 30px;
      &:after {
        width: 0;
        height: 0;
        border-left: 90px solid transparent;
        border-right: 90px solid transparent;
        border-top: 90px solid #2dbad7;
        content: "";
        position: absolute;
        z-index: 1;
        bottom: -90px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .form-container {
      height: 48%;
      background-color: #f6f1eb;
      padding-top: 100px;
      @media screen and (min-width: 768px) and (max-width: 992px) {
        min-height: 220px;
      }
      .yes-no {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        button {
          border: none;
          width: 84px;
          height: 84px;
          color: #fff;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          transform: rotate(0deg);
          transition: 0.5s ease-out all;

          &:after {
            content: "";
            width: 84px;
            height:84px;
            position: absolute;
            top: 0; left:0;
            border-radius: 40%;
              box-shadow: 0px 0px 25px -2px rgba(45,186,215,0.84);
              opacity: 0;
              transition: opacity 0.3s ease-in-out;
          }
          &:hover{
            &:after {
              transition: opacity 0.3s ease-in-out;
              opacity: 1;
              }
            //transform: scale(1.05);
            //transform: rotate(360deg);
            border-radius: 40%;
            transition: 0.3s ease-in-out;
          }
          &.no-btn {
            background-color: #ed5ca2;
          }
          &.yes-btn {
            background-color: #00bfb3;
          }
        }
        .yes-no-text {
          background-color: #fff;
          min-width: 72px;
          min-height: 72px;
          border-radius: 50%;
          margin: 0 10px;
          display: flex;
          flex-direction: column;
          font-size: 16px;
          line-height: 1.2;
          justify-content: center;
          span {
            &:nth-child(1) {
              color: #00bfb3;
            }
            &:nth-child(2) {
              color: #2dbad7;
            }
            &:nth-child(3) {
              color: #ed5ca2;
            }
          }
        }
      }
    }
  }
  .question-wrap-text {
    background-color: #2dbad7;
    text-align: left;
    color: #fff;
    font-size: 42px;
    font-weight: bold;
    line-height: 1;
    font-family: 'Arial', serif;
  }
  .question-icon {
    position: absolute;
    bottom: 30px;
    right: 30px;
  }
  .question-bg-left {
    padding: 25px 25px 100px 25px;
  }
  .graph-container {
    height: 100%;
    background-color: #f6f1eb;
    display: flex;
    flex-direction: column;
    .percentage-match {
      height: 145px;
      padding: 10px 130px 10px 160px;
      text-align: left;
      position: relative;
      @media screen and (min-width: 992px) and (max-width: 1026px) {
        padding: 10px 80px 10px 160px;
      }
      &.employer {
        color: #f7911e;
        h1,
        p {
          color: #f7911e;
        }
      }
      &.employee {
        color: #00bfb3;
        h1,
        p {
          color: #00bfb3;
        }
      }
      .heart-icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 84px;
        height: 84px;
        border-radius: 50%;
        border: 2px solid;
        left: 38px;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-size: 45px;
        }
      }
      h1 {
        font-size: 65px;
        font-weight: bold;
        line-height: 1;
        @media screen and (max-width: 576px){
          font-size: 28px !important;
        }
        @media screen and (max-width: 350px) {
          font-size: 24px !important;
        }
      }
      h2 {
        @media screen and (max-width: 576px){
          font-size: 18px;
        }
      }
      p {
        font-size: 22px;
        line-height: 1;
        width: 90%;
        @media screen and (max-width: 576px) {
          font-size: 14px;
          width: 100%;
        }
      }
      .next-question {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        background-color: #002776;
        &:hover {
          background-color: #00a0ff;
        }
      }
    }
    .analytics-height{
      display: flex;
      height: 100%;
      min-height: 330px;
      .analytics-left {
        width: 49%;
        background-color: #fff;
      }
      .analytics-right {
        width: 51%;
        background-color: #00bfb3;
      }
    }
  }
}



@media screen and (max-width: 768px) {
  .question-inner{
    flex-direction: column;
    .question-img-left{
      overflow: hidden;
      .ant-image {
        max-height: 300px;
      }
      img{
        //object-fit: initial;
        //width: 100%;
        //height: initial;
      }
    }
    .question-wrap-text.question-text-top{
      padding: 20px 20px 30px 30px;
      line-height: 34px;
      font-size: 32px;
    }
    .question-icon{
      display: none;
    }
    .question-wrap{
      .question-text-top:after{
        border-left: 60px solid transparent;
        border-right: 60px solid transparent;
        border-top: 60px solid #2dbad7;
        content: "";
        position: absolute;
        z-index: 1;
        bottom: -60px;
      }
      .form-container{
        padding-top: 79px;
        padding-bottom: 25px;
      }
    }
    .question-bg-left{
      padding: 20px 20px 30px 30px;
      line-height: 34px;
      font-size: 32px;
    }
    .graph-container{
      .percentage-match{
        padding: 15px 15px 15px 90px;
        height: auto;
        h1{
          font-size: 42px;
          //margin-bottom: 0 !important;
        }
        .heart-icon{
          position: absolute;
          transform: unset;
          width: 60px;
          height: 60px;
          top: 20px;
          left: 15px;
          span{
            font-size: 34px;
          }
        }
        .next-question{
          padding: 6px 7px 6px 10px;
          height: auto;
          top: 12px;
          transform: none;
          font-size: 18px;

          .caret-right{
            top: 1px;
            text-transform: capitalize;
            position: relative;
            margin-left: 6px;
          }
        }
        p{
          margin-bottom: 6px;
          width: 100%;
        }
      }
      .analytics-height{
        flex-direction: column;
        height: initial;
        min-height: initial;
        .analytics-left{
          width: 100%;
          height: 330px;
        }
        .analytics-right{
          width: 100%;
          height: 330px;
        }
      }
    }
  }
}

@media screen and (max-width: 375px) {
  .question-inner {
    .graph-container {
      .percentage-match {
        padding: 15px 15px 15px 60px;
        .heart-icon {
          width: 40px;
          height: 40px;
          left: 10px;
          span {
            font-size: 24px;
          }
        }
      }
    }
  }
}