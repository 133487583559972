.hays-footer{
  background: #001b64;
  font-size: 12px;
  color: #ffffff;
  padding: 25px;
  @media screen and (max-width: 768px){
    padding: 25px 15px;
  }
  .footer-top {
    padding: 0 15px;
    @media screen and (max-width: 992px){
      padding: 0;
    }
    h2 {
      color: #fff;
      margin-bottom: 20px !important;
      font-size: 32px;
      @media screen and (max-width: 345px) {
        font-size: 24px;
      }
    }
    h4 {
      color: #fff;
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 10px;
      line-height: 1.2;
    }
    ul {
      padding: 0;
      margin-bottom: 30px;
      list-style: none;
      li {
        margin-bottom: 5px;
        a {
          color: #fff;
          font-size: 16px;
          &:hover {
            color: #00a0ff;
          }
          @media screen and (max-width: 576px) {
            font-size: 14px;
          }
        }
      }
    }
  }
  .first{
    margin-bottom: 30px;
    font-size: 16px;
  }
  p {
    margin: 0;
  }
  .copyright {
    color: #ffffff;
  }
}