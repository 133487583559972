@import '~antd/dist/antd.css';

@font-face {
  font-family: "hays-branding-icons";
  src: url("./fonts/hays-branding-icons.ttf") format('ttf'),
  url("./fonts/hays-branding-icons.woff") format('woff'),
  url("./fonts/hays-branding-icons.woff2") format('woff2');
}

body {
  background: #c8cbc9;
}

.App {
  max-width: 1200px;
  margin: 0 auto;
  background: #ffffff;
}

.page-wrapper {
  width: 100%;
  height: 100%;
}

.main-title {
  padding: 0 15px;
  h1 {
    text-align: left;
    text-transform: uppercase;
    @media screen and (min-width: 345px) and (max-width: 450px){
      font-size: 22px;
      line-height: 28px;
    }
    @media screen and (max-width: 345px) {
      font-size: 18px;
      line-height: 24px;
    }
  }
}

.content-wrapper {
  background: white;
  padding: 15px 15px 50px 15px;
  p {
    font-size: 16px;
    color: #213a6f;
    font-weight: 500;
  }
}

#root {
  white-space: pre-line;
}

h1, h2, h3 {
  color: #002776;
}

h1 {
  font-size: 32px;
  margin-bottom: 7px !important;
}

.green-separator {
  width: 100%;
  height: 10px;
  background-color: #00bfb3;
}

.btn {
  border: none;
  color: #fff;
  padding: 8px 20px;
  font-size: 22px;
  line-height: 1;
  cursor: pointer;
  height: auto;
}

.ant-image {
  margin: auto;
  .ant-image-img-placeholder {
    background-color: transparent;
  }
  &.ant-image-error {
    img {
      object-fit: contain !important;
      max-width: 100px;
    }
  }
}

.mobile{
  display: none !important;
}

@media screen and (max-width: 768px) {

  h1, h2, h3{
    color: #213a6f;
    line-height: initial;
  }

  h1{
    line-height: 32px;
    font-size: 30px;
  }

  p{
    line-height: 22px;
  }

  .desktop{
    display: none !important;
  }

  .mobile{
    display: block !important;
  }

  .btn{
    font-size: 16px;
  }

  .content-wrapper{
    padding: 15px 15px 20px 15px;
  }
}