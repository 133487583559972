.pie-left-my {
  transform: rotate(90deg);
  z-index: 1;
  animation: pie-left-my 1s .5s both;
  &:before {
    background: conic-gradient(#f775af, 32%, #0000 0);
  }
}

.pie-right-my {
  transform: rotate(5deg);
  z-index: 0;
  animation: pie-right-my 1s .5s both;
  &:before {
    background: conic-gradient(#0b2672, 50%, #0000 0);
  }
}

.pie-bottom-my {
  transform: rotate(-144deg);
  z-index: 2;
  animation: pie-bottom-my 1s .5s both;
  &:before {
    background: conic-gradient(#fda433, 55%, #0000 0);
  }
}

@keyframes pie-bottom-my{
  from {
    transform: rotate(-144deg)
  }
  to{
    transform: rotate(72deg)
  }
}

@keyframes pie-left-my {
  from {
    transform: rotate(90deg)
  }
  to{
    transform: rotate(-90deg)
  }
}

@keyframes pie-right-my {
  from {
    transform: rotate(5deg)
  }
  to{
    transform: rotate(-5deg)
  }
}
