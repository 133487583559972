$green-color: #00bfb3;
$blue-color: #007fa9;

.page-info-wrapper{
  text-align: left;

  .text-body {
    padding-top: 20px;
  }

  .infographic-container{
    display: flex;
    height: 380px;
    overflow: hidden;

    .choose{
      width: 50%;
      position: relative;
      background: url("./assets/bg-intro.jpg") no-repeat;
      background-size: cover;
      img{
        width: 100%;
      }
      .triangle{
        position: absolute;
        width: 366px;
        height: 180px;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        background: url("./assets/triangle.svg") no-repeat top center;
        background-size: 100% auto;
        .triangle-text {
          padding-top: 20px;
          p{
            color: $blue-color;
            line-height: 24px;
            font-size: 18px;
          }
          &:after {
            content: "";
            background: url("./assets/profiles.svg") no-repeat;
            background-size: cover;
            width: 46px;
            height: 39px;
            display: block;
            margin: auto;
          }
        }
      }

      .actions{
        display: flex;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        & > * {
          width:50%;
          text-align: center;
          position: relative;
          button{
            position: absolute;
            bottom: 45px;
            left: 0;
            right: 0;
            margin: auto;
            width: 180px;
            max-width: 220px;
            transition: 0.3s linear;
            height: auto;
            //display: flex;
            //justify-content: center;
            //align-items: center;
            //white-space: pre-line;
            &:hover {
              color: #fff;
              background-color: #00a0ff!important;
              transition: 0.3s linear;
            }
          }
        }
        .employer {
          button {
            background-color: #ed5ca2;
          }
        }
        .jobseeker {
          button {
            background-color: #ed5ca2;
          }
        }
      }
    }

    .info {
      color: #fff;
      font-size: 22px;
      line-height: 1.2;
      &.info1{
        width: 25%;
        background: url("./assets/intro-info-1.jpg") no-repeat 100% 100%;
        background-size: cover;
        padding: 30px 55px;
        .info1-top {
          margin-bottom: 20px;
          &:before {
            content: "";
            background: url("./assets/quote-up.png") no-repeat;
            background-size: 100% 100%;
            width: 23px;
            height: 17px;
            display: block;
            margin-bottom: 5px;
          }
          &:after {
            content: "";
            background: url("./assets/quote-down.png") no-repeat;
            background-size: 100% 100%;
            width: 23px;
            height: 17px;
            display: block;
            margin: 0 15px 0 auto;
          }
        }
      }
      &.info2{
        width: 25%;
        background-color: #00bfb3;
        text-align: center;
        padding: 30px 15px 0;
        position: relative;
        &:before {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 0;
          height: 0;
          border-top: 100px solid transparent;
          border-left: 190px solid #fff;
          border-bottom: 100px solid transparent;
          z-index: 1;
          @media screen and (max-width: 576px){
            border-top: 60px solid transparent;
            border-left: 90px solid #fff;
            border-bottom: 60px solid transparent;
          }
        }
        &:after {
          content: "";
          position: absolute;
          bottom: 15px;
          left: 50%;
          transform: translateX(-50%);
          background: url("./assets/info-com.png") no-repeat;
          background-size: cover;
          width: 187px;
          height: 201px;
          z-index: 2;
          @media screen and (max-width: 576px){
            width: 116px;
            height: auto;
          }
        }
        .house {
          width: 208px;
          height: 35px;
          background-image: url("./assets/house.png");
          background-repeat: repeat-x;
          background-position: 0 0;
          margin: 0 auto 15px;
          position: relative;
          z-index: 4;
          &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 78%;
            height: 100%;
            background-image: url("./assets/house-blue.png");
            animation: house 1s ease;
          }
        }
        .flexible3 {
          position: relative;
          z-index: 3;
        }
      }
    }
  }
}

@keyframes house {
  0% {
    width: 0;
  }
}

.lang-jp .infographic-container .choose .actions .btn{
  width: 230px;
  max-width: 230px;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: pre-line;
}
@media screen and (max-width: 768px) {
  .page-info-wrapper{
    .infographic-container{
      display: initial;
      height: 380px;
      width: 100%;
      overflow: initial;
      .choose{
        width: 100%;
        background: none;
        .triangle{
          max-width: 230px;
          width: 80%;
          height: 100%;
          .triangle-text{
            padding-top: 10px;
            &:after{
              background-size: contain;
              width: 35px;
              height: 30px;
            }
            p{
              margin-bottom: 7px;
              line-height: initial;
              font-size: 15px;
            }
          }
        }
      }

      .actions{
        button{
          bottom: 25px !important;
          max-width: 140px !important;
          font-size: 16px;


        }
      }
      .info{
        font-size: 16px;
        &.info1{
          width: 100%;
          background: url("./assets/intro-info-1-mobile.jpg") no-repeat 100% 100%;
          background-size: cover;
        }
        .author{
          text-align: right;
        }

        &.info2{
          width: 100%;
          position: relative;
          min-height: 280px;
          padding: 65px 50% 15px 15px;
          &:before,
          &:after {
            right: 15px;
            top: 50%;
            transform: translate(0, -50%);
            left: auto;
          }
          &:before {
            right: 45px;
          }
        }
      }
    }
  }
}
