.analytics-right {
  font-family: 'Arial', serif;
  &__content {
    width: 100%;
    height: 100%;
    position: relative;
    color: #fff;
    font-size: 22px;
  }
  .em-text {
    line-height: 1;
    text-align: left;
    span {
      font-size: 45px;
      font-weight: bold;
    }
  }
  .er-1 {
    background: url("./assets/resume/triangle-up.png") no-repeat center bottom #0c2671;
    background-size: 100% 114px;
    .em-text {
      max-width: 150px;
      position: absolute;
      top: 10px;
      left: 10px;
      z-index: 1;
      @media screen and (max-width: 360px) {
        font-size: 18px;
      }
    }
    .ant-image {
      position: absolute;
      bottom: 30px;
      right: 15px;
      @media screen and (max-width: 360px) {
        max-width: 175px;
      }
    }
  }
  .ee-1 {
    background-color: #00bfb3;
    .em-text {
      width: 80%;
      position: absolute;
      bottom: 15px;
      left: 15px;
      z-index: 3;
      @media screen and (max-width: 350px) {
        font-size: 18px;
      }
    }
    .ant-image {
      position: absolute;
      &.img-1 {
        left: 10px;
        top: 20px;
        z-index: 2;
        @media screen and (max-width: 450px) {
          max-width: 170px;
        }
      }
      &.img-2 {
        right: 60px;
        top: 30px;
        z-index: 1;
        @media screen and (max-width: 450px) {
          max-width: 150px;
        }
      }
    }
  }
  .ee-2 {
    background: url("./assets/ee/ee-2-2.png") no-repeat right center #213a6f;
    background-size: auto 100%;
    .em-text {
      display: flex;
      max-width: 335px;
      position: absolute;
      bottom: 20px;
      left: 10px;
      font-weight: bold;
      z-index: 1;
      .per {
        margin-right: 10px;
        font-size: 45px;
      }
    }
    .ant-image {
      position: absolute;
      top: 15px;
      left: 50%;
      transform: translateX(-50%);
      @media screen and (max-width: 350px) {
        max-width: 200px;
      }
    }
  }
  .er-2 {
    background-color: #001b64;
    .em-text {
      max-width: 125px;
      margin-left: 15px;
      @media screen and (max-width: 360px) {
        font-size: 18px;
      }
      span {
        color: #00bfb3;
      }
    }
    .er-item {
      height: 50%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-right: 15px;
      position: relative;
      .ant-image {
        margin: 0;
      }
      .absolute {
        position: absolute;
        z-index: 1;
        top: 50%;
        transform: translateY(-50%);
        &.clock {
          left: 30px;
          @media screen and (max-width: 360px) {
            max-width: 90px;
          }
        }
        &.calendar {
          left: 15px;
          @media screen and (max-width: 360px) {
            max-width: 120px;
          }
        }
      }
    }
  }
  .er-3 {
    background: url("./assets/er/er-3-2.png") no-repeat right bottom #fca333;
    background-size: auto 256px;
    .em-text {
      max-width: 175px;
      position: absolute;
      left: 10px;
      top: 10px;
      z-index: 1;
      @media screen and (max-width: 360px) {
        font-size: 18px;
      }
    }
    .ant-image {
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
      @media screen and (max-width: 768px) {
        max-width: 230px;
      }
    }
  }
  .ee-3 {
    background-color: #007fa9;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .em-text {
      font-weight: bold;
      padding: 10px;
      line-height: 1.2;
      @media screen and (max-width: 350px) {
        font-size: 18px;
      }
    }
    .em-col-wrap {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      .em-col-item {
        font-weight: bold;
        margin: 0 10px;
        max-width: 110px;
        line-height: 1.2;
        span {
          font-size: 45px;
        }
        .em-col {
          padding: 10px;
          font-size: 18px;
          @media screen and (max-width: 768px) {
            font-size: 16px;
          }
          &.first {
            background-color: #00c7b3;
            min-height: 175px;
          }
          &.second {
            background-color: #2ebad7;
            min-height: 110px;
          }
          span {
            font-size: 22px;
          }
        }
      }
    }
  }
  .er-4 {
    background-color: #f7911e;
    padding-top: 20px;
    .em-text {
      padding: 0 20px 10px;
      line-height: 1.2;
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
      @media screen and (max-width: 360px) {
        font-size: 18px;
      }
    }
    .ant-image {
      @media screen and (max-width: 768px){
        max-width: 300px;
      }
      @media screen and (max-width: 360px) {
        max-width: 250px;
      }
    }
  }
  .ee-4 {
    background-color: #213a6f;
    .em-text {
      padding: 10px;
      @media screen and (max-width: 450px) {
        font-size: 18px;
      }
      .item {
        margin-bottom: 10px;
        display: flex;
        .per {
          color: #2ebad7;
          font-size: 42px;
          margin-right: 10px;
          font-weight: bold;
          span {
            font-size: 42px;
          }
        }
      }
    }
    .ant-image {
      @media screen and (max-width: 450px) {
        max-width: 200px;
      }
    }
  }
  .er-5 {
    background-color: #1b2e70;
    display: flex;
    padding: 0 25px;
    .ant-image {
      margin: auto 0;
      min-width: 139px;
      @media screen and (max-width: 375px) {
        min-width: 120px;
      }
    }
    .em-text {
      font-weight: bold;
      padding: 35px 0 20px 10px;
      .item {
        //display: flex;
        //margin-top: 20px;
        &:first-child {
          //margin-top: 30px;
        }
        &:nth-child(1),
        &:nth-child(3) {
          .per {
            color: #00a0ff;
          }
        }
        &:nth-child(2) {
          .per {
            color: #20b29d;
          }
        }
        .per {
          font-size: 42px;
          margin-right: 10px;
        }
        p {
          color: #fff;
          margin: 0;
          font-size: 22px;
          font-weight: bold;
          @media screen and (max-width: 375px) {
            font-size: 18px;
          }
          @media screen and (max-width: 350px) {
            font-size: 16px;
          }
        }
      }
    }
  }
  .ee-5 {
    .em-text {
      max-width: 220px;
      padding: 10px;
      position: relative;
      z-index: 3;
      @media screen and (max-width: 450px) {
        font-size: 18px;
      }
      .per {
        font-size: 42px;
        font-weight: bold;
      }
    }
    .ee51 {
      position: absolute;
      z-index: 2;
      bottom: 70px;
      right: 40px;
      @media screen and (max-width: 400px) {
        max-width: 70px;
      }
    }
    .ee52 {
      position: absolute;
      bottom: 15px;
      left: 60px;
      z-index: 1;
      @media screen and (max-width: 400px) {
        max-width: 210px;
      }
    }
  }
}