.page-end-wrapper {
  padding-top: 30px;
}
.thank-you {
  background-color: #f6f1eb;
  display: flex;
  @media screen and (max-width: 1090px) {
    flex-direction: column;
  }
  .yes-no-result {
    min-height: 280px;
    display: flex;
    @media screen and (max-width: 1090px) {
      background-color: #0dbbb8;
      justify-content: space-between;
      //align-items: center;
      min-height: auto;
      position: relative;
    }
    @media screen and (max-width: 425px) {
      //flex-direction: column;
    }
    .yes-no-result-text {
      font-size: 22px;
      font-weight: bold;
      color: #fff;
      line-height: 1.2;
      text-align: left;
      padding: 30px 15px;
      width: 195px;
      background-color: #0dbbb8;
      @media screen and (max-width: 425px) {
        font-size: 18px;
        padding: 15px 0 15px 15px;
      }
      h1 {
        color: #fff;
        text-transform: uppercase;
        font-size: 55px;
        font-weight: bold;
        line-height: 1.2;
      }
    }
    .yes-no-result-img {
      width: 225px;
      position: relative;
      background-color: #0dbbb8;
      @media screen and (max-width: 1090px) {
        width: auto;
        padding: 15px;
      }
      @media screen and (max-width: 576px) {
        max-width: 200px;
      }
      @media screen and (max-width: 425px) {
        max-width: 150px;
        padding: 0 5px 0 0;
      }
      &.ee-yes {
        @media screen and (max-width: 576px) {
          display: flex;
          align-items: center;
        }
      }
      .img-wrap {
        @media screen and (max-width: 1090px) {
          position: relative;
          z-index: 1;
          display: flex;
          align-items: center;
          height: 100%;
        }
      }
      .ant-image {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        &.eeYes1 {
          left: 30px;
          @media screen and (max-width: 576px) {
            max-width: 40px;
            //max-height: 104px;
          }
        }
        &.eeYes2 {
          right: -70px;
          @media screen and (max-width: 576px) {
            max-width: 89px;
            //max-height: 127px;
          }
        }
        &.eeNo {
          right: -60px;
          @media screen and (max-width: 580px) {
            max-width: 130px;
          }
        }
        &.erYes {
          left: -15px;
          transform: translateY(-45%);
        }
        &.erNo {
          right: -125px;
        }
        @media screen and (max-width: 1090px) {
          position: static;
          transform: none !important;
        }
        @media screen and (max-width: 576px) {
          width: 100% !important;
          height: auto;
          //object-fit: cover;
        }
      }
    }
    .triangle-right {
      background: url("./assets/triangle-right.png") no-repeat #f6f1eb;
      background-size: 100% 100%;
      width: 144px;
      height: 100%;
      @media screen and (max-width: 1090px) {
        position: absolute;
        top: 0;
        right: 0;
      }
      @media screen and (max-width: 576px) {
        width: 100px;
      }
    }
  }
  .thank-you-text {
    text-align: left;
    padding: 30px 40px;
    @media screen and (max-width: 1090px) {
      padding: 30px 15px;
    }
    h2 {
      font-size: 22px;
      color: #213a6f;
      line-height: 1.2;
    }
    p {
      font-size: 18px;
      line-height: 1.3;
      margin-bottom: 20px;
    }
    .thank-you-btn {
      display: flex;
      justify-content: space-between;
      &.er {
        justify-content: flex-start;
        .btn {
          &.search {
            margin-left: 30px;
          }
          @media screen and (max-width: 450px) {
            padding: 8px 10px;
            width: 50%;
          }
        }
      }
    }
    .btn {
      background-color: #002776;
      font-size: 18px;
      //font-weight: bold;
      height: auto;
      padding: 10px 15px;
      width: 30%;
      text-align: center;
      line-height: 1.1;
      display: flex;
      justify-content: center;
      align-items: center;
      @media screen and (max-width: 450px) {
        font-size: 12px;
        padding: 8px 5px;
      }
      &:hover {
        color: #fff;
      }
      &.search {
        background-color: #007fa9;
      }
      &.salary {
        background-color: #00bfb3;
      }
    }
  }



}
.wchat-modal-container{
  position: fixed;
  width: 100%;
  height: 100%;
  background: #0000004f;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.share-this {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 20px 0;
  border-bottom: 2px solid #0dbbb8;
  margin-bottom: 20px;
  p {
    margin: 0 5px 0 0;
    font-weight: bold;
    font-size: 18px;
  }
  &__icon {
    width: 37px;
    height: 37px;
    background-color: #1c8dd2;
    color: #fff;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
    &:hover {
      color: #fff;
    }
  }
}

.article-item {
  display: flex;
  text-align: left;
  justify-content: space-between;
  margin-bottom: 15px;
  background-color: #34b6d3;
  @media screen and (max-width: 425px) {
    //flex-direction: column;
  }
  .img-article {
    width: 190px;
    position: relative;
    min-height: 156px;
    @media screen and (max-width: 425px) {
      width: 160px;
      min-height: 0;
    }
    @media screen and (max-width: 400px) {
      width: 120px;
    }
    @media screen and (max-width: 335px) {
      width: 100px;
    }
    a {
      display: block;
      width: 100%;
      height: 100%;
    }
    &:before,
    &:after {
      //content: "";
      display: block;
      position: absolute;
    }
    &:before {
      background: url("./assets/r-a-1.png") no-repeat;
      background-size: 100% 100%;
      width: 78px;
      height: 61px;
    }
    .ant-image {
      width: 190px;
      height: 100%;
      @media screen and (max-width: 425px) {
        width: 160px;
      }
      @media screen and (max-width: 400px) {
        width: 120px;
      }
      @media screen and (max-width: 335px) {
        width: 100px;
      }
      img {
        min-height: 156px;
        object-fit: cover;
        @media screen and (max-width: 425px) {
          min-height: 140px;
        }
        @media screen and (max-width: 335px) {
          min-height: 135px;
        }
      }
    }
  }
  &.r-article {
    background-color: #34b6d3;
    .img-article {
      &:before {
        bottom: 0;
        left: 0;
      }
      &:after {
        background: url("./assets/r-a-2.png") no-repeat;
        background-size: 100% 100%;
        width: 70px;
        height: 51px;
        bottom: 0;
        right: 5px;
      }
    }
  }
  &.d-article {
    background-color: #0dbbb8;
    .img-article {
      &:before {
        top: 0;
        left: 0;
      }
      &:after {
        background: url("./assets/d-a-2.png") no-repeat;
        background-size: 100% 100%;
        width: 70px;
        height: 51px;
        bottom: 0;
        left: 0;
      }
    }
  }
  .text-article {
    padding: 15px;
    p {
      font-weight: bold;
      margin-bottom: 10px;
      line-height: 1;
      @media screen and (max-width: 576px) {
        font-size: 12px;
        margin-bottom: 5px;
      }
    }
    a {
      font-size: 22px;
      color: #fff;
      line-height: 1.2;
      @media screen and (max-width: 576px) {
        font-size: 14px;
        line-height: 1;
      }
      @media screen and (max-width: 335px) {
        font-size: 13px;
      }
    }
  }
}
