.analytics-left {
  font-family: 'Arial', serif;
  &__content {
    width: 100%;
    height: 100%;
    position: relative;
    color: #fff;
    font-size: 22px;
  }
  .em-text {
    line-height: 1;
    text-align: left;
    font-family: 'Arial', serif;
    span {
      font-size: 45px;
      font-weight: bold;
    }
  }
  .per {
    font-weight: bold;
  }
  .er-1 {
    background-color: #007fa9;
    .em-text {
      padding: 10px;
      margin-bottom: 10px;
      line-height: 1.1;
      @media screen and (max-width: 375px) {
        font-size: 18px;
      }
    }
    .er-chart {
      width: 240px;
      height: 240px;
      position: relative;
      margin: auto;
      .item {
        font-size: 22px;
        line-height: 1;
        max-width: 70px;
        text-align: left;
        position: absolute;
        left: 45px;
        .per {
          font-size: 31px;
        }
        &.hard {
          top: 35px;
          z-index: 3;
        }
        &.soft {
          bottom: 35px;
          z-index: 3;
        }
        &.others{
          z-index: 2;
          top: 55px;
          max-width: 90px;
          left: 138px;
        }
      }
    }
  }
  .ee-1 {
    background-color: #002776;
    .em-text {
      padding: 10px;
      font-weight: bold;
      line-height: 1.1;
      width: 80%;
      position: relative;
      z-index: 4;
    }
    .item {
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      position: absolute;
      flex-direction: column;
      line-height: 1;
      font-size: 18px;
      @media screen and (max-width: 375px) {
        font-size: 16px;
      }
      .per {
        font-size: 32px;
      }
      &.com {
        background-color: #fca333;
        width: 164px;
        height: 164px;
        right: 70px;
        top: 45px;
        @media screen and (max-width: 390px) {
          width: 150px;
          height: 150px;
        }
        @media screen and (max-width: 375px) {
          width: 140px;
          height: 140px;
          top: 75px;
        }
        @media screen and (max-width: 345px) {
          right: 40px;
          top: 65px;
        }
      }
      &.problem {
        background-color: #f776b0;
        width: 186px;
        height: 186px;
        bottom: 0;
        left: 0;
        padding: 0 30px;
        @media screen and (max-width: 375px) {
          width: 160px;
          height: 160px;
        }
      }
      &.teamwork {
        background-color: #a86db0;
        width: 144px;
        height: 144px;
        bottom: 0;
        right: 0;
        @media screen and (max-width: 375px) {
          width: 130px;
          height: 130px;
        }
      }
    }
  }
  .ee-2 {
    background-color: #f776b0;
    .em-text {
      display: flex;
      max-width: 325px;
      position: absolute;
      bottom: 20px;
      left: 10px;
      font-weight: bold;
      z-index: 1;
      .per {
        font-size: 45px;
        margin-right: 10px;
      }
    }
    .ant-image {
      position: absolute;
      top: 50px;
      left: 50%;
      transform: translateX(-50%);
      @media screen and (max-width: 350px) {
        max-width: 200px;
      }
    }
  }
  .er-2 {
    background: url("./assets/er/er-left-2-2.png") no-repeat right bottom #00bfb3;
    background-size: auto 217px;
    .em-text {
      padding: 10px;
      max-width: 200px;
      position: relative;
      z-index: 1;
      @media screen and (max-width: 360px) {
        font-size: 18px;
      }
      .per {
        font-size: 45px;
      }
    }
    .ant-image {
      position: absolute;
      bottom: 15px;
      right: 20px;
      @media screen and (max-width: 375px) {
        max-width: 190px;
      }
    }
  }
  .ee-3 {
    background: url("./assets/ee/ee-left-3.png") no-repeat #00c7b3;
    display: flex;
    justify-content: center;
    align-items: center;
    .em-text {
      max-width: 145px;
      font-weight: bold;
      .per {
        font-size: 45px;
      }
    }
  }
  .er-3 {
    background: rgb(0,191,179);
    background: linear-gradient(180deg, rgba(0,191,179,1) 45%, rgba(0,127,169,1) 45%);
    .em-text {
      padding: 10px;
      position: relative;
      z-index: 1;
      max-width: 185px;
      line-height: 1.1;
      @media screen and (max-width: 360px) {
        font-size: 18px;
      }
      .per {
        font-size: 45px;
      }
    }
    .ant-image {
      position: absolute;
      right: 35px;
      top: 50%;
      transform: translateY(-50%);
      @media screen and (max-width: 360px) {
        max-width: 90px;
        right: 25px;
      }
    }
  }
  .er-4 {
    background-color: #ed5ca2;
    display: flex;
    align-items: center;
    .ant-image {
      margin: 0 35px 0 20px;
      min-width: 103px;
      @media screen and (max-width: 360px) {
        min-width: 90px;
      }
    }
    .em-text {
      line-height: 1.2;
      padding-right: 20px;
      @media screen and (max-width: 360px) {
        font-size: 18px;
      }
      .per {
        font-size: 45px;
      }
    }
  }
  .ee-4 {
    background-color: #9356a2;
    .ant-image {
      position: absolute;
      bottom: 10px;
      left: 50%;
      transform: translateX(-50%);
      @media screen and (max-width: 450px) {
        max-width: 220px;
      }
    }
    .em-text {
      padding: 10px;
      line-height: 1.1;
      max-width: 70%;
      .per {
        font-size: 42px;
      }
    }
  }
  .er-5 {
    background-color: #0dbbb8;
    .ant-image {
      margin-top: 10px;
      @media screen and (max-width: 375px) {
        max-width: 200px;
        margin-bottom: 7px;
      }
    }
    .em-text {
      display: flex;
      padding: 5px 30px 0 15px;
      font-weight: bold;
      line-height: 1.1;
      @media screen and (max-width: 360px) {
        font-size: 18px;
      }
      .per {
        font-size: 42px;
        margin-right: 15px;
      }
    }
  }
  .ee-5 {
    background: #ed5ca2 url("./assets/ee/ee-left-5-1.png") no-repeat 98% 35px;
    .ant-image {
      position: absolute;
      bottom: 10px;
      right: 10px;
      @media screen and (max-width: 450px) {
        max-width: 200px;
      }
    }
    .em-text {
      padding: 10px;
      line-height: 1.2;
      max-width: 55%;
      @media screen and (max-width: 450px) {
        font-size: 18px;
      }
      .per {
        font-size: 42px;
      }
    }
  }
}

@property --percentage{
  syntax: '<number>';
  inherits: true;
  initial-value: 1;
}

.pie-ee-3 {
  --percentage:15;
  --border-thickness:44px;
  --color:white;
  --w:290px;

  width: var(--w);
  aspect-ratio: 1;
  position: relative;
  display: inline-grid;
  place-content: center;
  @media screen and (max-width: 350px) {
    --border-thickness:35px;
    --w:270px;
  }
  &:before,
  &:after {
    content: "";
    position: absolute;
    border-radius: 50%;
  }
  &:before {
    inset: 0;
    background:
            radial-gradient(farthest-side,var(--color) 98%,#0000) top/var(--border-thickness) var(--border-thickness) no-repeat,
            conic-gradient(#0000 0, #0000 15%,var(--color) calc(var(--percentage)*1%));
    -webkit-mask: radial-gradient(farthest-side,#0000 calc(99% - var(--border-thickness)),#000 calc(100% - var(--border-thickness)));
    mask: radial-gradient(farthest-side,#0000 calc(99% - var(--border-thickness)),#000 calc(100% - var(--border-thickness)));
  }
  &:after {
    inset: calc(50% - var(--border-thickness)/2);
    background: var(--color);
    transform: rotate(calc(var(--percentage)*3.6deg)) translateY(calc(50% - var(--w)/2));
  }
  &.animate {
    animation: pie 1s .5s both;
  }
  &.no-round {
    &:before {
      background-size: 0 0, auto;
    }
    &:after {
      content: none;
    }
  }
}

@keyframes pie{
  from{--percentage: 100}
  to{--percentage: 0}
}
