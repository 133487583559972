.pie-left-cn {
  transform: rotate(180deg);
  z-index: 1;
  animation: pie-left-cn 1s .5s both;
  &:before {
    background: conic-gradient(#f775af, 38%, #0000 0);
  }
}

.pie-right-cn {
  transform: rotate(11deg);
  z-index: 0;
  animation: pie-right-cn 1s .5s both;
  &:before {
    background: conic-gradient(#0b2672, 0%, #0000 0);
  }
}

.pie-bottom-cn {
  transform: rotate(-90deg);
  z-index: 2;
  animation: pie-bottom-cn 1s .5s both;
  &:before {
    background: conic-gradient(#fda433, 65%, #0000 0);
  }
}

@keyframes pie-bottom-cn {
  from {
    transform: rotate(-90deg)
  }
  to{
    transform: rotate(36deg)
  }
}

@keyframes pie-left-cn {
  from {
    transform: rotate(180deg)
  }
  to{
    transform: rotate(-90deg)
  }
}

@keyframes pie-right-cn {
  from {
    transform: rotate(11deg)
  }
  to{
    transform: rotate(-11deg)
  }
}
