.pie-left-jp {
  transform: rotate(90deg);
  z-index: 1;
  animation: pie-left-jp 1s .5s both;
  &:before {
    background: conic-gradient(#f775af, 22%, #0000 0);
  }
}

.pie-right-jp {
  transform: rotate(11deg);
  z-index: 0;
  animation: pie-right-jp 1s .5s both;
  &:before {
    background: conic-gradient(#0b2672, 50%, #0000 0);
  }
}

.pie-bottom-jp {
  transform: rotate(-115deg);
  z-index: 2;
  animation: pie-bottom-jp 1s .5s both;
  &:before {
    background: conic-gradient(#fda433, 43%, #0000 0);
  }
}

@keyframes pie-bottom-jp{
  from {
    transform: rotate(-115deg)
  }
  to{
    transform: rotate(115deg)
  }
}

@keyframes pie-left-jp {
  from {
    transform: rotate(90deg)
  }
  to{
    transform: rotate(-90deg)
  }
}

@keyframes pie-right-jp {
  from {
    transform: rotate(11deg)
  }
  to{
    transform: rotate(-11deg)
  }
}
