.hays-header-mobile{
  height: initial;
  background: white;
  padding: 0;

  .separator{
    border-top: 1px solid #e5e5e5;
    margin: 10px 15px;
  }

  .hamburguer{
    color: #121515;
    background: transparent;
    border: none;
    padding: 0;
    max-width: 55px;
    @media screen and (max-width: 375px){
      max-width: 30px;
    }
    img{
      width: 100%;
    }
  }

  .language-row{
    background: white;
    border-bottom: 1px solid #f5f5f5;
    //margin-bottom: 10px;

    .ant-select{
      width: initial !important;
    }

    .ant-select-focused .ant-select-selector,
    .ant-select-selector:focus,
    .ant-select-selector:active,
    .ant-select-open .ant-select-selector {
      border-color: #d9d9d9 !important;
      box-shadow: none !important;
    }
  }

  .header-main-wrapper{
    background-color: white;
    text-align: left;
    padding: 15px 15px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    img{
      cursor: pointer;
      max-height: 100%;
      float: left;
      text-align: left;
      //max-width: 140px;
      //max-width: fit-content;
      max-width: 250px;
    }

  }

  .ant-layout-footer{
    text-align: left;
  }

  #header-main {
    justify-content: space-between;
    flex-wrap: nowrap;
  }

  .logo-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .right-nav{
    display: flex;
    .search-wrapper{
      display: flex;
      flex-direction: column;
      justify-content: center;
      #site-search{
        height: 33px;

        .hays_form{
          height: 100%;
          position: relative;
          line-height: 0;

          input{
            margin: 0;
            display: inline-block;
            border: 0;
            background: #eee;
            width: 100%;
            font-size: 14px;
            padding: 0 60px 0 10px;
            min-height: 33px;
            height: 33px;
          }
          button{
            height: 70%;
            margin: 2%;
            position: absolute;
            top: 0;
            right: 5px;
            font-size: 16px;
            border: none;
            display: flex;
            flex-direction: column;
            justify-content: center;
            img{
              width: 14px;
            }
          }
        }
      }
    }

    #nav-social{
      margin-left: 10px;
      position: relative;
      top: 2px;

      ul {
        display: flex;
        list-style: none;
        padding: 0;
        margin: 0;

        li{
          height: 33px;
          background: #009ed9;
          margin-left: 5px;
          padding: 5px;
          line-height: 0;

          a{
            margin-left: 0;
            margin-right: 0;
            padding: 0;
            font-size: 16px;
            color: #fff;
            position: relative;
            top: 3px;
          }

          .icon-linkedin:before {
            line-height: 0;
            font-family: "hays-branding-icons";
            content: '\f0e1';
          }

          .icon-twitter:before {
            font-family: "hays-branding-icons";
            content: '\f099';
          }

          .icon-facebook:before {
            font-family: "hays-branding-icons";
            content: '\f09a';
          }
        }
      }
    }
  }
  .hays-col-2 {
    width: 180px;
  }
}

.drawer-mobile{
  .ant-drawer-body{
    padding: 0;
  }
}

#nav-main-mobile{
  position: relative;
  &>ul{
    list-style: none;
    display: flex;
    justify-content: flex-start;
    padding: 0;
    margin-bottom: 1rem;
    height: 20px;
    line-height: initial;
    margin-left: 0px;
    margin-top: 5px;
    flex-direction: column;

    li{
      //margin-right: 15px;
      border-bottom: 1px solid #eee;
      padding: 12px;
      a{
        color: #009ed9;
        text-decoration: none;
        font-weight: 500;
        font-size: 14px;
      }
    }
  }

  #hays-worldwide{
    //background: #009ed9;
    //border-top: 3px solid #002776;
    //position: absolute;
    //right: -20px;
    //top: 50px;
    //z-index: 1000;
    display: none;
    width: 100%;
    padding: 15px 0 10px 0px;
    list-style: none;

    &.open{
      display: block;
    }

    ul{
      display: block;
      position: relative;
      padding-left: 0;
      li{
        line-height: initial;
        list-style: none;
        margin-right: 30px;
        text-align: left;
        margin-bottom: 5px;
        border-bottom: none;
        padding: 10px;
        a{
          color: #009ed9;
          text-decoration: none;
          font-weight: 500;
          font-size: 14px;
        }
      }
    }
  }
  .ant-menu {
    border: none;
    li {
      margin: 0;
      padding: 0;
      border: none;
      .ant-menu-submenu-title {
        padding: 0 !important;
        color: #009ed9;
        height: auto;
        line-height: normal;
      }
      .ant-menu-sub {
        background: none;
        .ant-menu-item-group-title {
          color: #002776;
        }
      }
    }
  }
}

.drawer-mobile {
  .ant-drawer-header {
    padding: 15px 15px 0;
    .ant-drawer-header-title {
      justify-content: flex-end;
      .ant-drawer-close {
        margin: 0;
        color: #000;
        font-size: 24px;
        padding: 0;
      }
    }
  }
  .ant-menu-submenu-arrow {
    right: 5px;
  }
}