.pie-left-hk {
  transform: rotate(90deg);
  z-index: 1;
  animation: pie-left-hk 1s .5s both;
  &:before {
    background: conic-gradient(#f775af, 30%, #0000 0);
  }
}

.pie-right-hk {
  transform: rotate(11deg);
  z-index: 0;
  animation: pie-right-hk 1s .5s both;
  &:before {
    background: conic-gradient(#0b2672, 50%, #0000 0);
  }
}

.pie-bottom-hk {
  transform: rotate(-101deg);
  z-index: 2;
  animation: pie-bottom-hk 1s .5s both;
  &:before {
    background: conic-gradient(#fda433, 47%, #0000 0);
  }
}

@keyframes pie-bottom-hk{
  from {
    transform: rotate(-101deg)
  }
  to{
    transform: rotate(101deg)
  }
}

@keyframes pie-left-hk {
  from {
    transform: rotate(90deg)
  }
  to{
    transform: rotate(-90deg)
  }
}

@keyframes pie-right-hk {
  from {
    transform: rotate(11deg)
  }
  to{
    transform: rotate(-11deg)
  }
}
